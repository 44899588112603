import React from "react";
import AgentDashboard from "../AgentDashboard/AgentDashboard";

const MainDashboard = ({fetchAgentInfo,agentId}) => {
  const role = JSON.parse(localStorage.getItem("role"));

  return (
    <>
      <AgentDashboard fetchAgentInfo={fetchAgentInfo} agentId={agentId} />
   
    </>
  );
};

export default MainDashboard;
