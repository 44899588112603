import React, { useEffect } from "react";
import MainDashboard from "../Components/Dashboard/MainDashboard/MainDashboard";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const DashboardPage = () => {

  const userToken = JSON.parse(localStorage.getItem("userToken"));

  useEffect(() => {
    // Set the meta title
    document.title =
      "Dashboard - RevCatalyst - Boosting Your Hotel Performance";

    // Create or update the meta description tag
    let metaDescription = document.querySelector('meta[name="description"]');
    if (!metaDescription) {
      metaDescription = document.createElement("meta");
      metaDescription.name = "description";
      document.head.appendChild(metaDescription);
    }
    metaDescription.content =
      "Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property.";
  }, []);


    const fetchAgentInfo = async (id) => {
      try {
        // setLoader(true);
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/v1/agent/details/${id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userToken}`,
            },
          }
        );
        const data = await response.json();
        localStorage.setItem("properties",JSON.stringify(data.properties));
        localStorage.setItem("selectedProperty", JSON.stringify(data.properties[0]));
       
        localStorage.setItem("agentInfo", JSON.stringify(data.agentInfo));
        
      } catch (error) {
        console.error("Error:", error);
      } finally {
        // setLoader(false);
      }
    };


    const agentId = JSON.parse(localStorage.getItem("agentDetails"))?.id;

    useEffect(() => {

      if (agentId) {
        fetchAgentInfo(agentId);
      }
    } , [agentId]);

  return (
    <>
      <div className="main_container">
        <MainDashboard fetchAgentInfo={fetchAgentInfo} agentId={agentId}    />
      </div>
    </>
  );
};

export default DashboardPage;
